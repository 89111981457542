import React, { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import moment from 'moment-timezone'
import { Button } from '@material-ui/core'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const Documents = (props) => {
    const [rows, setRows] = useState([])

    const download = (doc_id, doc_name) => {
        api.download_document( doc_id)
            .then( data => {
                fileDownload( data, doc_name)
            })
    }

    useEffect(() => {
        api.documents()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])

    const config = [
        { align: 'left', label: 'Дата', heading: true, value: row => moment(row.datetime).format('DD.MM.YYYY HH:mm') },
        { align: 'left', label: 'Име', heading2: true, value: row => row.file_name },
        { align: 'left', label: '', value: row => (
            <Button variant="contained" size="small" color="primary" onClick={ () => download(row.id, row.file_name)}>
              Изтегли
            </Button>
        )},
    ]
    return (
    <PageTemplate
        title='Документи'
        description='Списък с публикувани документи за сградата'>
      <DataTable config={config} rows={rows} />
    </PageTemplate>
    )
}

export default Documents
