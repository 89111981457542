import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import React, { useEffect, useState } from 'react'
import { withRouter, HashRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Provider, inject, observer } from 'mobx-react'

//import * as Sentry from '@sentry/browser'


import './fontawesome_library'
import './assets/base.scss'
import { ThemeProvider, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Slide, Snackbar, Button } from '@material-ui/core'

import MuiTheme from './theme'

import config from './config'
import api from './api'
import { AppStore, AuthStore, UIStore } from './stores'
import {
    MainLayout,
    Login,
    ResetPassword,
    ResetPasswordConfirm,
    SetEmail,
    Polls,
    PollVote,
    PollVoteWithToken,
    CommunalReport,
    CommunalReportAll,
    PaymentsReport,
    Revisions,
    ReportIssue,
    NavigationMenu,
    Offices,
    Messages,
    ClubParticipants,
    Notifications,
    Obligation,
    Documents,
    Toast,
    Error404,
} from './components'

const stores = {
    app_store: new AppStore( api),
    auth_store: new AuthStore( api),
    ui_store: new UIStore(),
}


//Sentry.init( config.SENTRY)

window.__STATE__ = stores


/*
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, eventId: null }
    }

    componentDidCatch(error, errorInfo) {
      this.setState({ error })
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo)
          const eventId = Sentry.captureException(error)
          this.setState({eventId})
      })
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
              <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>
            )
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}
*/

const Routes = props => {
    const theme = useTheme()
    const use_navigation_page = useMediaQuery( theme.breakpoints.down('md'))
    const location = useLocation()

    const posthog = config.posthog
    if (posthog) {
        posthog.capture( location.pathname)
    }

    useEffect(() => {
        updateVersion()
    }, [location.pathname])

    return (
    <Switch>
      <Redirect exact from={config.urls.root} to={ use_navigation_page ? config.urls.home : config.urls.obligation} />

      <Route path={config.urls.login} component={Login} />
      <Route path={config.urls.logout} component={Logout} />
      <Route path={config.urls.password_reset_confirm} component={ResetPasswordConfirm} />
      <Route path={config.urls.password_reset} component={ResetPassword} />
      <Route path={config.urls.offices_anon} component={Offices.Anon} />
      <Route path={config.urls.poll_vote_token} component={PollVoteWithToken} />

      <LoginRequired path={[
          config.urls.initial_setup,
          config.urls.set_password,
          config.urls.home,
          config.urls.report_issue,
          config.urls.communal_report,
          config.urls.communal_report_all,
          config.urls.payments_report,
          config.urls.revisions,
          config.urls.poll_vote,
          config.urls.messages,
          config.urls.club_participants,
          config.urls.notifications,
          config.urls.documents,
          config.urls.obligation,
          config.urls.polls,
          config.urls.offices,
        ]} >
        <Switch location={location} key={location.pathname}>
          <Route path={config.urls.initial_setup} component={SetEmail} />
        </Switch>
        <SetupRequired>
          <MainLayout>
            <Switch location={location} key={location.pathname}>
              <Route path={config.urls.home} component={NavigationMenu} />
              <Route path={config.urls.report_issue} component={ReportIssue} />
              <Route path={config.urls.communal_report} component={CommunalReport} />
              <Route path={config.urls.communal_report_all} component={CommunalReportAll} />
              <Route path={config.urls.payments_report} component={PaymentsReport} />
              <Route path={config.urls.revisions} component={Revisions} />
              <Route path={config.urls.poll_vote} component={PollVote} />
              <Route path={config.urls.messages} component={Messages} />
              <Route path={config.urls.club_participants} component={ClubParticipants} />
              <Route path={config.urls.notifications} component={Notifications} />
              <Route path={config.urls.obligation} component={Obligation} />
              <Route path={config.urls.polls} component={Polls} />
              <Route path={config.urls.offices} component={Offices.Auth} />
              <Route path={config.urls.documents} component={Documents} />
            </Switch>
          </MainLayout>
        </SetupRequired>
      </LoginRequired>
      <Route component={Error404} />
    </Switch>
    )
}

const Logout = inject('auth_store')( observer( props => {
    const { auth_store } = props
    useEffect(() => {
        auth_store.logout()
    }, [auth_store])
    return <Redirect to={config.urls.root} />
}))

const LoginRequired = inject('auth_store')( observer( props => {
    const { auth_store, ...rest_props } = props
    if (!config.USE_AUTH || auth_store.api_token) {
        return <Route {...rest_props} />
    }
    return <Redirect to={config.urls.login} />
}))
const SetupRequired = inject('auth_store')( observer( props => {
    const { auth_store, ...rest_props } = props
    if (auth_store.user_email && auth_store.user.has_usable_password) {
        return <Route {...rest_props} />
    }
    return <Redirect to={config.urls.initial_setup} />
}))

const VersionUpdateMessage = props => {
    const transition = props => (<Slide {...props} direction="up" />)
    const { refresh } = props

    return (
    <Snackbar
       open={true}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
       TransitionComponent={transition}
       message='Има нова версия на приложението.'
       action={
         <Button color="secondary" variant="contained" size="small" onClick={refresh}>
         Обнови
         </Button>
       }
    />
    )
}


const App = (props) => {
    const [pendingUpdate, setPendingUpdate] = useState(null)
    const onUpdate = (upd) => {
        console.log(1111111111111, upd)
        setPendingUpdate(upd)
    }
    useEffect(() => {
        const ui_store = stores.ui_store
        serviceWorkerRegistration.register({
            onUpdate: setPendingUpdate,
            setPushManager: stores.app_store.set_push_manager,
        })

        api.set_server_error_handler(
            error => ui_store.setToast(
                'В момента има технически проблем, заради който не можем да изпълним заявката Ви.'
                + 'Нашият екип работи по отстраняването му. Моля, опитайте по-късно!',
                'error'
            )
        )
        api.set_network_error_handler(
            error => ui_store.setToast('В момента няма връзка със сървъра. Моля, опитайте по-късно!', 'error')
        )
        return () => {
            api.set_server_error_handler( null)
            api.set_network_error_handler( null)
        }
    }, [])

    return (
    <Provider {...stores}>
      <HashRouter>
        <ScrollToTop>
          <ThemeProvider theme={MuiTheme}>
            {/* <ErrorBoundary> */}
            <Routes />
            <Toast />
            { pendingUpdate && (<VersionUpdateMessage refresh={pendingUpdate} />) }
            { /*</ErrorBoundary> */}
          </ThemeProvider>
        </ScrollToTop>
      </HashRouter>
    </Provider>
    )
}


const updateVersion = () => {
    const sw = navigator.serviceWorker
    if (!sw) {
        return
    }
    sw.getRegistrations().then((registrations) => {
        try {
            for (const reg of registrations) {
                reg.update()
            }
        } catch (error) {
            if (error.name !== 'InvalidStateError') {
                throw error
            }
        }
    })
}

const ScrollToTop = withRouter(
    ({ children, location: { pathname } }) => {
        useEffect(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }, [pathname])
        return children || null
    }
)

//export default Sentry.withProfiler(App)
export default App


