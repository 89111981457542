import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
    Box,
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    Dialog,
} from '@material-ui/core'

import PageTemplate from './page_template'
import api from '../api'


const Participant = ({participant, onOpen}) => {
    return (
    <Card className="p-5 mb-spacing-6-x2">
        <Grid container spacing={0} className="align-items-center">
          <Grid item xl={6}>
            <a
              href={participant.url || "javascript:;"}
              target={participant.url ? "_blank" : ""}
              rel="noreferrer"
              className="d-flex rounded card-box-hover-rise">
              <img
                src={participant.logo}
                alt=""
                className="img-fit-container rounded"
              />
            </a>
          </Grid>
          <Grid item xl={6}>
            <CardContent className="px-0 px-xl-4 pt-4 pb-xl-4">
              <a href="javascript:;" onClick={(e) => e.preventDefault()}>
                <h2 className="font-weight-bold mb-4">
                {participant.name}
                </h2>
              </a>
              <p className="text-black-50 font-size-lg">
                {participant.description}
              </p>
              {participant.phone && (
                <p className="text-black-50 font-size-lg">
                {participant.phone}
                </p>
              )}
              {participant.address && (
                <p className="text-black-50 font-size-lg">
                {participant.address}
                </p>
              )}
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" variant="outlined"
                    onClick={() => onOpen(participant)}
                >
                Отстъпка {participant.discount_percent}%
                </Button>
            </CardActions>

          </Grid>
        </Grid>
    </Card>
    )
}


const ClubParticipants = inject('ui_store')( ({ ui_store, ...props }) => {
    const [rows, setRows] = useState([])
    const [openParticipant, setOpenParticipant] = useState(null)

    useEffect(() => {
        api.club_participants()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])

    const checkStatus = (participant) => {
        api.club_status()
            .then( () => {
                setOpenParticipant(participant)
                ui_store.setToast(null)
            })
            .catch( (error) => {
                ui_store.setToast('Имате неплатени задължения. Моля, опитайте отново след като ги заплатите!', 'error')
            })
    }
    return (
    <PageTemplate
        title='Клуб Акорд'
        description='Kато лоялен клиент на Акорд ползвате отстъпки при следните търговци'>

        <Grid container>
            { rows.map( (row, idx) => (
            <Grid key={idx} item xs={12}>
              <Box p={3}>
                <Participant participant={row} onOpen={checkStatus} />
              </Box>
            </Grid>
            ))}
        </Grid>
        { openParticipant && (
            <ClubStatusOk
                participant={openParticipant}
                onClose={() => setOpenParticipant(null)}
            />
        )}
    </PageTemplate>
    )
})


const ClubStatusOk = inject('auth_store')( observer( ({ auth_store, participant, onClose }) => {
    const user = auth_store.user
    return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{ paper: 'modal-content rounded-lg' }}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
            <h1>-{participant.discount_percent}%</h1>
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">
            {participant.name}
        </h4>
        <p className="mb-0 font-size-lg ">
          Като лоялен клиент на Акорд с КИН:{user.username}, ползвате {participant.discount_percent}% отстъпка.
        </p>
        <p className="mb-0 font-size-lg ">
          Благодарим Ви, че сме заедно!
        </p>

        <br/>
        <Button onClick={onClose} className="btn-primary">
          Затвори
        </Button>

      </div>
    </Dialog>
    )
}))


export default ClubParticipants
