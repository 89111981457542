import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

import config from '../config'


const AppInfo = (props) => {
    return (
        <Dialog
          classes={{ paper: 'modal-content rounded-lg' }}
          open={true}
          onClose={() => props.onClose()}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Акорд Онлайн</DialogTitle>
          <DialogContent className="p-4" dividers={true}>
            <Box display='flex' justifyContent='left'>
              <DialogContentText variant="h6">
              Версия: 1.0 (beta) - {config.VERSION}
            </DialogContentText>
            </Box>
            <Box display='flex' justifyContent='justify'>
              <DialogContentText>

              "Акорд Онлайн" е продукт на Кантора Акорд - Домоуправителят на Варна
              </DialogContentText>
            </Box>
          </DialogContent>
          <Box display='flex' justifyContent='center'>
            <DialogActions className="p-4">
              <Button onClick={() => props.onClose()} className="btn-primary">
              Затвори
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
    )
}

export default AppInfo



